<template>
    <failure />
</template>

<script>
// @ is an alias to /src
import failure from "@/components/agent/failure";
export default {
  name: "agent_failure",
  components: {
    failure,
  }
};
</script>